import { Button, Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link, useNavigation } from "react-router-dom";
import FrameBox from "../../ui/containers/FrameBox";
import { Box, styled } from "@mui/system";
import CenteredContentBox from "../../ui/containers/CenteredContentBox";
import { ReactComponent as Separator }
	from "../../assets/decorative/separator-hor.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useDispatch } from "react-redux";
import { openScanner, openListSetup } from "../../store/utils-slice";
import useGameSettings from "../../hooks/use-game-settings";
import Loading from "../../ui/Loading";
import RippedBox from "../../ui/containers/RippedBox";
import AppPromo from "../AppPromo";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const GameLogo = styled("img")(({ theme }) => ({
	height: "90px",
	[theme.breakpoints.only("xs")]: {
		height: "70px",
	},
}));


const Line = styled(Separator)(({ theme }) => ({
	height: "40px",
	fill: "currentColor",
	padding: theme.spacing(0, 0, 3, 0),
}));


function MainGameMenu({ game }) {
	const { slug, name } = game;
	const { getGameSettings } = useGameSettings();
	const { logo } = getGameSettings(slug);
	const dispatch = useDispatch();
	const { state } = useNavigation();
	const [newsOpen, setNewsOpen] = useState(false);

	function handleNewList() {
		dispatch(openListSetup());
	}

	function handleScannerOpen() {
		dispatch(openScanner());
	}

	if (state === "loading") {
		return (
			<CenteredContentBox>
				<Loading />
			</CenteredContentBox>
		);
	}

	return (
		<>
			<CenteredContentBox>
				<Stack spacing={2} alignItems="center">
					<Button
						variant="contained"
						size="large"
						onClick={() => setNewsOpen(true)}
						startIcon={<NewReleasesIcon />}
						sx={{width: 230, marginBottom: 2}}
					>
						News
					</Button>
					<FrameBox
						frameStyle="frame1"
						bgcolor="darkBgr.main"
						color="darkBgr.contrastText"
					>
						<Stack spacing={2} m={{ xs: 5, sm: 9 }}>
							<GameLogo src={logo} alt={name} />
							<Line />
							<Button
								component={Link}
								to="factions"
								variant="contained"
								size="large"
							>
								Browse Factions
							</Button>
							<Button variant="contained" size="large" onClick={handleNewList}>
								Prepare for War
							</Button>
							<Button
								variant="contained"
								size="large"
								onClick={handleScannerOpen}
								startIcon={<QrCodeScannerIcon />}
							>
								Scan Card
							</Button>
						</Stack>
					</FrameBox>
					<Box pt={4}>
						<AppPromo />
					</Box>
				</Stack>
			</CenteredContentBox>
			<FrameDialog
				open={newsOpen}
				responsive
				onClose={() => setNewsOpen(false)}
				actions={[
					{
						id: "Cancel",
						icon: CloseIcon,
						handler: () => setNewsOpen(false),
					}
				]}
			>
				<FrameHeader title="News" />
				<FrameDialogContent>
					<Typography gutterBottom>
						<strong>AdeptiCon 2025</strong> was a blast! We want to thank EVERYONE who played, participated in demos, shopped with us, listened to lore and was just awesome in <MuiLink href="https://www.facebook.com/hashtag/mke" target="_blank" rel="noopener" sx={{fontWeight: "bold"}}>#MKE</MuiLink>!
					</Typography>
					<Typography variant="h5" gutterBottom>Conquest TLAOK Tournament Placements:</Typography>
					<Typography gutterBottom>
						<strong>First place</strong> - Bryan with W'adrhun
						<br/>
						<strong>Second place</strong> - Karl with W'adrhun
						<br/>
						<strong>Third place</strong> - Big Mike with Old Dominion
						<br/>
						<strong>Wooden Spoon</strong> Winner- Dan Bertzyk , presented by Andrew “Yes Chef” Campbell who has been the historical winner of this spoon!
					</Typography>
					<Typography variant="h5" gutterBottom>Painting categories winners:</Typography>
					<Typography gutterBottom>
						<strong>Best army</strong> - Justin Trudeau Nords
						<br/>
						<strong>Best regiment</strong> - Eric Marchand Old Dominion
						<br/>
						<strong>Best monster</strong> - Matthew Munson Spires
						<br/>
						<strong>Best character</strong> - Tyrel W'adrhun
						<br/>
						<strong>Best cavalry</strong> - Brute Christopher Duffy Old Dominion
					</Typography>
					<Typography textAlign="center">
						<strong>Keep an eye out on our website for the lists!</strong>
					</Typography>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default MainGameMenu;